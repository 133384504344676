import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/AISlugGenerator.css';

const AISlugGenerator = ({ selectedProducts, onComplete }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 0 });
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 3;
    const DELAY_BETWEEN_REQUESTS = 500;

    const generateSlugs = useCallback(async () => {
        if (!selectedProducts.length) {
            setError('No products selected');
            return;
        }

        setIsGenerating(true);
        setError(null);
        setProgress({ current: 0, total: selectedProducts.length });

        let currentIndex = 0;
        const token = localStorage.getItem('accessToken');

        while (currentIndex < selectedProducts.length) {
            try {
                const currentProductId = selectedProducts[currentIndex];

                console.log(`Processing product ${currentIndex + 1} of ${selectedProducts.length}, ID: ${currentProductId}`);

                const response = await axios.post(
                    `${config.apiUrl}/api/products/products/batch_generate_slugs/`,
                    {
                        product_ids: [currentProductId],
                        current_index: 0 // Always use 0 for single product
                    },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                        timeout: 30000
                    }
                );

                // Log the full response for debugging
                console.log('Server response:', response.data);

                // Consider success if either is_complete is true OR we have batch_results
                const isSuccess = response.data.is_complete ||
                    (response.data.batch_results && response.data.batch_results.length > 0);

                if (!isSuccess) {
                    throw new Error('Server did not confirm successful processing');
                }

                // Successfully processed this product
                currentIndex++;
                setProgress({
                    current: currentIndex,
                    total: selectedProducts.length
                });

                console.log(`Successfully processed product ${currentIndex} of ${selectedProducts.length}`);

                // Add delay between requests
                await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_REQUESTS));

            } catch (error) {
                console.error(`Error generating slug for product ${currentIndex + 1}:`, error);

                // Increment retry count for this specific product
                const newRetryCount = retryCount + 1;
                setRetryCount(newRetryCount);

                if (newRetryCount < MAX_RETRIES) {
                    console.log(`Retrying product ${currentIndex + 1} (attempt ${newRetryCount + 1}/${MAX_RETRIES})`);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    continue; // Retry the same product
                }

                console.log(`Max retries reached for product ${currentIndex + 1}, moving to next product`);
                // Move to next product after max retries
                currentIndex++;
                setRetryCount(0);

                // Update progress even for failed products
                setProgress({
                    current: currentIndex,
                    total: selectedProducts.length
                });

                // Add delay before next product
                await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_REQUESTS));
            }
        }

        setIsGenerating(false);
        setRetryCount(0);
        console.log('All products processed');
        onComplete();
    }, [selectedProducts, retryCount, onComplete]);

    return (
        <div className="ai-slug-generator">
            <button
                onClick={generateSlugs}
                disabled={isGenerating || !selectedProducts.length}
                className="ai-slug-button"
            >
                {isGenerating ? 'Generating...' : 'Generate AI Slugs'}
            </button>

            {isGenerating && (
                <div className="progress-container">
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{ width: `${(progress.current / progress.total) * 100}%` }}
                        />
                    </div>
                    <div className="progress-text">
                        {progress.current} of {progress.total} completed
                        {retryCount > 0 && ` (Retry ${retryCount}/${MAX_RETRIES})`}
                    </div>
                </div>
            )}

            {error && (
                <div className="error-message">
                    {error}
                    <button
                        onClick={() => {
                            setError(null);
                            setRetryCount(0);
                            generateSlugs();
                        }}
                        className="retry-button"
                    >
                        Retry
                    </button>
                </div>
            )}
        </div>
    );
};

export default AISlugGenerator; 