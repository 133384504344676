import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/common.css';
import '../../styles/pages/dashboard/SettingsPage.css';

const SettingsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [progress, setProgress] = useState(0);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('DRAFT');
    const [isFetchingCategories, setIsFetchingCategories] = useState(true);
    const [username, setUsername] = useState('');
    const [updateExisting, setUpdateExisting] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsFetchingCategories(true);
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${config.apiUrl}/api/products/categories/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                setCategories(response.data.data || []);
            } catch (err) {
                console.error('Error fetching categories:', err);
                setError('Failed to load categories');
                setCategories([]);
            } finally {
                setIsFetchingCategories(false);
            }
        };

        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${config.apiUrl}/api/users/me/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                setUserRole(response.data.role);
                setUsername(response.data.username);
                if (response.data.default_category_id) {
                    setSelectedCategory(response.data.default_category_id.toString());
                }
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError('Failed to load user data');
            }
        };

        fetchCategories();
        fetchUserData();
    }, []);

    const handleGenerateProducts = async () => {
        if (!selectedCategory) {
            setError('Please select a category');
            return;
        }

        try {
            setIsLoading(true);
            setError(null);
            setInfoMessage('Starting product generation...');
            setProgress(0);

            const token = localStorage.getItem('accessToken');

            // Step 1: Trigger scraping
            console.log('Starting product scraping...');
            setInfoMessage('Scraping products...');

            const scrapeResponse = await axios.post(
                `${config.apiUrl}/api/products/scrape-products/`,
                {
                    username,
                    category: selectedCategory,
                    status: selectedStatus,
                    update_existing: String(updateExisting)
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 600000, // 10 minutes timeout
                }
            );

            // Log the scraping results
            console.log('Scraping Response:', {
                success: scrapeResponse.data.success,
                counts: scrapeResponse.data.counts_summary,
                productsCount: scrapeResponse.data.products?.length || 0,
                errors: scrapeResponse.data.errors
            });

            if (!scrapeResponse.data.success) {
                throw new Error(scrapeResponse.data.message || 'Scraping failed');
            }

            // Check if there are any products to process
            let productsToProcess = scrapeResponse.data.products || [];
            const { new_products, existing_products } = scrapeResponse.data.counts_summary;

            console.log(`Products to process: ${productsToProcess.length}`);
            console.log(`New products: ${new_products}, Existing products: ${existing_products}`);

            // If no products from scraper but we have existing products, fetch them from database
            if (productsToProcess.length === 0 && existing_products > 0 && updateExisting) {
                console.log('No products returned from scraper, fetching existing products from database...');
                setInfoMessage('Fetching existing products...');

                try {
                    const existingProductsResponse = await axios.post(
                        `${config.apiUrl}/api/products/products/get_existing_products/`,
                        {
                            username,
                            category: selectedCategory
                        },
                        {
                            headers: { 'Authorization': `Bearer ${token}` },
                            timeout: 300000, // 5 minutes timeout
                        }
                    );

                    if (existingProductsResponse.data.success && existingProductsResponse.data.products.length > 0) {
                        productsToProcess = existingProductsResponse.data.products;
                        console.log(`Fetched ${productsToProcess.length} existing products from database`);
                    } else {
                        console.warn('No existing products found in database');
                        setProgress(100);
                        setInfoMessage(`No products were found to update.`);
                        setIsLoading(false);
                        return;
                    }
                } catch (err) {
                    console.error('Error fetching existing products:', err);
                    // Continue with empty array as fallback
                    console.log('Continuing with empty products array...');
                }
            }

            if (productsToProcess.length === 0) {
                // No products to process at all
                setProgress(100);
                if (updateExisting) {
                    setInfoMessage(`No products found to update. ${existing_products} products already exist in the database.`);
                } else {
                    setInfoMessage(`No new products found. ${existing_products} products already exist in the database.`);
                }
                setIsLoading(false);
                return;
            }

            setProgress(50);
            setInfoMessage(`${updateExisting ? 'Updating' : 'Importing'} products...`);

            // Step 2: Import the scraped products
            console.log(`Sending ${productsToProcess.length} products for ${updateExisting ? 'update' : 'import'}`);

            const importResponse = await axios.post(
                `${config.apiUrl}/api/products/products/import_json/`,
                {
                    json_data: JSON.stringify(productsToProcess),
                    category: selectedCategory,
                    status: selectedStatus,
                    update_existing: String(updateExisting)
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 600000, // 10 minutes timeout
                }
            );

            console.log('Import Response:', {
                imported: importResponse.data.imported_count,
                updated: importResponse.data.updated_count || 0,
                failed: importResponse.data.failed_count,
                existing: importResponse.data.existing_count,
                errors: importResponse.data.errors
            });

            setProgress(100);

            // Use the counts from both responses
            const { imported_count, failed_count, updated_count = 0, existing_count = 0 } = importResponse.data;

            if (imported_count === 0 && updated_count === 0 && existing_count > 0 && failed_count === 0) {
                if (updateExisting) {
                    setInfoMessage(`All ${existing_count} products already exist and no updates were needed.`);
                } else {
                    setInfoMessage(`All ${existing_count} products already exist in the database.`);
                }
            } else {
                setInfoMessage(`Import completed: ${imported_count} products imported, ${updated_count} updated, ${failed_count} failed, ${existing_count} unchanged.`);
            }

        } catch (err) {
            console.error('Generation error:', err);
            console.error('Error details:', {
                response: err.response?.data,
                message: err.message
            });
            setError(err.response?.data?.errors?.[0] || err.response?.data?.message || err.message || 'Failed to generate products');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="dashboard-page">
            <div className="page-header">
                <h1>Settings</h1>
            </div>
            <div className="content-box">
                {error && <div className="error-message">{error}</div>}
                {infoMessage && <div className="info-message">{infoMessage}</div>}

                {userRole === 'SELLER' && (
                    <div className="settings-section">
                        <h2>Product Generation</h2>
                        <p>Generate products automatically from your store</p>

                        <div className="form-group">
                            <label>Category *</label>
                            {isFetchingCategories ? (
                                <div className="loading-text">Loading categories...</div>
                            ) : (
                                <select
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    required
                                    disabled={isLoading}
                                >
                                    <option value="">Select a category</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>

                        <div className="form-group">
                            <label>Initial Status *</label>
                            <select
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                                required
                                disabled={isLoading}
                            >
                                <option value="DRAFT">Draft</option>
                                <option value="PUBLISHED">Published</option>
                                <option value="OUT_OF_STOCK">Out of Stock</option>
                                <option value="ARCHIVED">Archived</option>
                            </select>
                        </div>

                        <div className="form-group checkbox-group">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={updateExisting}
                                    onChange={(e) => setUpdateExisting(e.target.checked)}
                                    disabled={isLoading}
                                />
                                Update prices for existing products
                            </label>
                            <div className="checkbox-help">
                                If checked, prices will be updated for products that already exist in the database.
                            </div>
                        </div>

                        <button
                            className="generate-button"
                            onClick={handleGenerateProducts}
                            disabled={isLoading || !selectedCategory}
                        >
                            {isLoading ? 'Generating...' : 'Generate Products'}
                        </button>

                        {isLoading && progress > 0 && (
                            <div className="progress-container">
                                <div className="progress-bar">
                                    <div
                                        className="progress-fill"
                                        style={{ width: `${progress}%` }}
                                    ></div>
                                </div>
                                <span className="progress-text">{progress}% Complete</span>
                            </div>
                        )}
                    </div>
                )}

                {userRole && userRole !== 'SELLER' && (
                    <div className="info-message">
                        This feature is only available for sellers.
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettingsPage; 