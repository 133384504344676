import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/ImportDataPage.css';

const ImportJsonPage = () => {
    const navigate = useNavigate();
    const [jsonData, setJsonData] = useState('');
    const [file, setFile] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingCategories, setIsFetchingCategories] = useState(true);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('DRAFT');
    const [infoMessage, setInfoMessage] = useState(null);
    const [updateExisting, setUpdateExisting] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsFetchingCategories(true);
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${config.apiUrl}/api/products/categories/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                setCategories(response.data.data || []);
            } catch (err) {
                console.error('Error fetching categories:', err);
                setError('Failed to load categories');
                setCategories([]);
            } finally {
                setIsFetchingCategories(false);
            }
        };
        fetchCategories();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === 'application/json') {
            setFile(selectedFile);
            setJsonData(''); // Clear text input when file is selected
            setError(null);
        } else {
            setError('Please select a valid JSON file');
            setFile(null);
        }
    };

    const handleJsonInputChange = (e) => {
        setJsonData(e.target.value);
        setFile(null); // Clear file when text input is used
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if ((!file && !jsonData) || !selectedCategory) {
            setError('Please provide either a JSON file or JSON data, and select a category');
            return;
        }

        const formData = new FormData();
        formData.append('category', selectedCategory);
        formData.append('status', selectedStatus);
        formData.append('update_existing', updateExisting.toString());

        try {
            setIsLoading(true);
            setError(null);
            setInfoMessage(null);

            // Get token from localStorage
            const token = localStorage.getItem('accessToken');
            if (!token) {
                setError('Authentication token not found. Please login again.');
                setIsLoading(false);
                return;
            }

            if (file) {
                formData.append('file', file);
            } else if (jsonData) {
                try {
                    const parsedData = JSON.parse(jsonData);
                    // Filter out invalid entries and clean data
                    const cleanedData = Array.isArray(parsedData) ? parsedData.map(item => {
                        // Skip invalid items
                        if (!item.name || item.name === 'No Name' || !item.price) {
                            return null;
                        }

                        // Clean the data
                        return {
                            name: item.name.trim(),
                            url: item.url?.trim() || '',
                            images: item.images ? [item.images.trim()] : [], // Convert single image to array
                            price: String(item.price).replace(/\s+/g, '').replace(/сўм/g, '').trim(),
                            old_price: item.old_price ? String(item.old_price).replace(/\s+/g, '').replace(/сўм/g, '').trim() : ''
                        };
                    }).filter(Boolean) : []; // Remove null entries

                    formData.append('json_data', JSON.stringify(cleanedData));
                } catch (err) {
                    setError('Invalid JSON format: ' + err.message);
                    setIsLoading(false);
                    return;
                }
            }

            const response = await axios.post(
                `${config.apiUrl}/api/products/products/import_json/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    timeout: 300000, // 5 minute timeout
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );

            const { imported_count, failed_count, existing_count, updated_count = 0 } = response.data;

            if (imported_count > 0 || updated_count > 0) {
                const successMessage = `Import completed: ${imported_count} products imported, ${updated_count} updated, ${failed_count} failed, ${existing_count} unchanged`;
                localStorage.setItem('importSuccess', JSON.stringify({
                    message: successMessage,
                    timestamp: Date.now()
                }));
                navigate('/dashboard/products');
            } else if (existing_count > 0 && imported_count === 0 && updated_count === 0 && failed_count === 0) {
                setInfoMessage(`All ${existing_count} products already exist in the database`);
            } else {
                setError('No new products were imported or updated');
            }
        } catch (err) {
            console.error('Import error:', err);
            const responseData = err.response?.data;

            if (responseData?.existing_count > 0 && responseData?.imported_count === 0 &&
                (responseData?.updated_count || 0) === 0 && responseData?.failed_count === 0) {
                setInfoMessage(`All ${responseData.existing_count} products already exist in the database`);
            } else {
                const errorMessage = err.response?.data?.errors
                    ? err.response.data.errors.join('\n')
                    : err.response?.data?.message || err.message;
                setError(`Import failed: ${errorMessage}`);
            }
        } finally {
            setIsLoading(false);
            setProgress(0);
        }
    };

    return (
        <div className="import-data-page">
            <h1>Import Products from JSON</h1>
            {error && <div className="error-message">{error}</div>}
            {infoMessage && <div className="info-message">{infoMessage}</div>}

            <form onSubmit={handleSubmit} className="import-form">
                <div className="form-group">
                    <label>Category *</label>
                    {isFetchingCategories ? (
                        <div className="loading-text">Loading categories...</div>
                    ) : (
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            required
                        >
                            <option value="">Select a category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="form-group">
                    <label>Initial Status *</label>
                    <select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        required
                    >
                        <option value="DRAFT">Draft</option>
                        <option value="PUBLISHED">Published</option>
                        <option value="OUT_OF_STOCK">Out of Stock</option>
                        <option value="ARCHIVED">Archived</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>JSON File</label>
                    <div className="import-file-input-container">
                        <input
                            type="file"
                            accept="application/json"
                            onChange={handleFileChange}
                            id="json-file-input"
                            className="import-file-input"
                            required={!jsonData}
                        />
                        <label htmlFor="json-file-input" className="import-file-button">
                            <i className="fas fa-upload"></i>
                            Choose JSON File
                        </label>
                        {file && (
                            <div className="import-file-name">
                                <i className="fas fa-file-code"></i>
                                {file.name}
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-group">
                    <label>Or Paste JSON Data</label>
                    <textarea
                        value={jsonData}
                        onChange={handleJsonInputChange}
                        placeholder={`Paste your JSON data here...`}
                        rows={10}
                        className="json-input"
                        required={!file}
                    />
                </div>

                <div className="form-group checkbox-group">
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={updateExisting}
                            onChange={(e) => setUpdateExisting(e.target.checked)}
                            disabled={isLoading}
                        />
                        Update prices for existing products
                    </label>
                    <div className="checkbox-help">
                        If checked, prices will be updated for products that already exist in the database.
                    </div>
                </div>

                {isLoading && progress > 0 && (
                    <div className="progress-container">
                        <div className="progress-bar">
                            <div
                                className="progress-fill"
                                style={{ width: `${progress}%` }}
                            ></div>
                        </div>
                        <span className="progress-text">{progress}% Uploaded</span>
                    </div>
                )}

                <div className="button-group">
                    <button
                        type="button"
                        onClick={() => navigate('/dashboard/products')}
                        className="cancel-button"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="submit-button"
                        disabled={isLoading || (!file && !jsonData)}
                    >
                        {isLoading ? 'Importing...' : 'Import Products'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ImportJsonPage;