import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/ImportDataPage.css';

const ImportDataPage = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [fieldSeparator, setFieldSeparator] = useState(',');
    const [labelSeparator, setLabelSeparator] = useState('|');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingCategories, setIsFetchingCategories] = useState(true);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('DRAFT');
    const [infoMessage, setInfoMessage] = useState(null);
    const [updateExisting, setUpdateExisting] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsFetchingCategories(true);
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${config.apiUrl}/api/products/categories/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                setCategories(response.data.data || []);
            } catch (err) {
                console.error('Error fetching categories:', err);
                setError('Failed to load categories');
                setCategories([]);
            } finally {
                setIsFetchingCategories(false);
            }
        };
        fetchCategories();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === 'text/csv') {
            setFile(selectedFile);
            setError(null);
        } else {
            setError('Please select a valid CSV file');
            setFile(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file || !selectedCategory) {
            setError('Please select both a file and a category');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('category', selectedCategory);
        formData.append('status', selectedStatus);
        formData.append('field_separator', fieldSeparator);
        formData.append('label_separator', labelSeparator);
        formData.append('update_existing', updateExisting.toString());

        setIsLoading(true);
        setError(null);
        setInfoMessage(null);

        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.post(
                `${config.apiUrl}/api/products/products/import_products/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );

            const { imported_count, failed_count, existing_count, updated_count = 0 } = response.data;

            if (imported_count > 0 || updated_count > 0) {
                const successMessage = `Import completed: ${imported_count} products imported, ${updated_count} updated, ${failed_count} failed, ${existing_count} unchanged`;
                localStorage.setItem('importSuccess', JSON.stringify({
                    message: successMessage,
                    timestamp: Date.now()
                }));
                navigate('/dashboard/products');
            } else if (existing_count > 0 && imported_count === 0 && updated_count === 0 && failed_count === 0) {
                setInfoMessage(`All ${existing_count} products already exist in the database`);
            } else {
                setError('No new products were imported or updated');
            }
        } catch (err) {
            console.error('Import error:', err);
            const responseData = err.response?.data;

            if (responseData?.existing_count > 0 && responseData?.imported_count === 0 &&
                (responseData?.updated_count || 0) === 0 && responseData?.failed_count === 0) {
                setInfoMessage(`All ${responseData.existing_count} products already exist in the database`);
            } else {
                setError(err.response?.data?.message || 'Import failed');
            }
        } finally {
            setIsLoading(false);
            setProgress(0);
        }
    };

    return (
        <div className="import-data-container">
            <h1>Import Products from CSV</h1>
            {error && <div className="error-message">{error}</div>}
            {infoMessage && <div className="info-message">{infoMessage}</div>}

            <form onSubmit={handleSubmit} className="import-form">
                <div className="form-group">
                    <label>Category *</label>
                    {isFetchingCategories ? (
                        <div className="loading-text">Loading categories...</div>
                    ) : (
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            required
                        >
                            <option value="">Select a category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="form-group">
                    <label>Initial Status *</label>
                    <select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        required
                    >
                        <option value="DRAFT">Draft</option>
                        <option value="PUBLISHED">Published</option>
                        <option value="OUT_OF_STOCK">Out of Stock</option>
                        <option value="ARCHIVED">Archived</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>CSV File *</label>
                    <div className="import-file-input-container">
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            id="csv-file-input"
                            className="import-file-input"
                            required
                        />
                        <label htmlFor="csv-file-input" className="import-file-button">
                            <i className="fas fa-upload"></i>
                            Choose CSV File
                        </label>
                        {file && (
                            <div className="import-file-name">
                                <i className="fas fa-file-csv"></i>
                                {file.name}
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Field Separator</label>
                        <input
                            type="text"
                            value={fieldSeparator}
                            onChange={(e) => setFieldSeparator(e.target.value)}
                            maxLength={1}
                            placeholder="Default: ,"
                        />
                    </div>

                    <div className="form-group">
                        <label>Label Separator</label>
                        <input
                            type="text"
                            value={labelSeparator}
                            onChange={(e) => setLabelSeparator(e.target.value)}
                            maxLength={1}
                            placeholder="Default: |"
                        />
                    </div>
                </div>

                <div className="form-group checkbox-group">
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={updateExisting}
                            onChange={(e) => setUpdateExisting(e.target.checked)}
                            disabled={isLoading}
                        />
                        Update prices for existing products
                    </label>
                    <div className="checkbox-help">
                        If checked, prices will be updated for products that already exist in the database.
                    </div>
                </div>

                {isLoading && progress > 0 && (
                    <div className="progress-container">
                        <div className="progress-bar">
                            <div
                                className="progress-fill"
                                style={{ width: `${progress}%` }}
                            ></div>
                        </div>
                        <span className="progress-text">{progress}% Uploaded</span>
                    </div>
                )}

                <div className="form-actions">
                    <button
                        type="button"
                        className="cancel-button"
                        onClick={() => navigate('/dashboard/products')}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="submit-button"
                        disabled={isLoading || !file}
                    >
                        {isLoading ? 'Importing...' : 'Import Products'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ImportDataPage;